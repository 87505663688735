import { useCallback, useState } from 'react';
import { useApi } from '../../../router/router';
import { LoadingComponent } from '../../loading/loading';
import { AlbumComponent } from './album/album';
import { PhotosContainer } from './photos.styled';
import { Album } from '../../../models/album';
import { AlbumOverlay } from './album-overlay/album-overlay';

export function PhotosComponent(): JSX.Element {
    const { albums, isLoaded } = useApi();
    const [shownAlbum, setShownAlbum] = useState<Album | undefined>();

    const closeOverlay = useCallback(() => {
        setShownAlbum(undefined);
        document.body.style.setProperty('overflow', 'auto');
    }, []);

    if (!isLoaded) {
        return <LoadingComponent />;
    }

    return (
        <>
            <PhotosContainer>
                {albums.map((album, index) => {
                    return (
                        <AlbumComponent
                            key={index}
                            album={album}
                            onAlbumClicked={() => setShownAlbum(album)}
                        />
                    );
                })}
            </PhotosContainer>

            {shownAlbum && (
                <AlbumOverlay
                    album={shownAlbum}
                    closeOverlay={closeOverlay}
                ></AlbumOverlay>
            )}
        </>
    );
}
