import { Lens } from '../../../models/lens';
import { MountComponent } from '../mount/mount';

interface LensComponentProps {
    lens: Lens;
}

export function LensComponent({ lens }: LensComponentProps): JSX.Element {
    return (
        <tr>
            <td>
                <MountComponent mount={lens.mount} />
            </td>

            <td>
                <i>{lens.maker}</i>
            </td>

            <td>{lens.name}</td>

            <td>{lens.focalLength.toString()}mm</td>

            <td>f{lens.minAperture.toString()}</td>
        </tr>
    );
}
