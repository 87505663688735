import { PhotoApiResponse } from './api/photos-api-response';

export interface Photo {
    id: number;
    url: string;
    thumb: string;
}

const makeThumb = (url: string): string => {
    const charpos = url.lastIndexOf('/');
    const dirname = url.substring(0, charpos);
    const basename = url.substring(charpos);

    return `${dirname}/thumbs${basename}`;
};

export function makePhoto(params: PhotoApiResponse): Photo {
    return {
        id: Number(params.picture_id),
        url: params.picture_url,
        thumb: makeThumb(params.picture_url),
    };
}
