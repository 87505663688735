import React, { useContext } from 'react';
import { RouterProvider } from 'react-router-dom';
import { FooterComponent } from '../components/footer/footer';
import './router.css';
import { routes } from './routes';
import { UseBlogApi, useBlog } from '../hooks/use-blog';
import {
    UseConfigurationApi,
    useConfiguration,
} from '../hooks/use-configuration';
import clsx from 'clsx';
import { useBootstrap, UseBootstrapApi } from '../hooks/use-bootstrap';

type ConfigurationContextType = UseConfigurationApi;

type ApiContextType = UseBootstrapApi & UseBlogApi;

export const ApiContext = React.createContext<ApiContextType>({
    photos: [],
    cameras: [],
    lenses: [],
    albums: [],
    isLoaded: false,

    isPostsLoaded: false,
    posts: [],
    page: 0,
    pageChangeComponent: <></>,
    getPost: () => undefined,
});

export const useApi = () => useContext(ApiContext);

export const ConfigurationContext =
    React.createContext<ConfigurationContextType>({
        isMobile: false,
        isLandscape: false,
        getPage: () => undefined,
    });

export function Router(): JSX.Element {
    const apiContextValue: ApiContextType = {
        ...useBootstrap(),
        ...useBlog(),
    };

    const configurationContextValue = useConfiguration();

    return (
        <ConfigurationContext.Provider value={configurationContextValue}>
            <ApiContext.Provider value={apiContextValue}>
                <div
                    className={clsx(
                        'main',
                        configurationContextValue.isMobile
                            ? 'main-mobile'
                            : 'main-desktop',
                    )}
                >
                    <RouterProvider router={routes} />

                    <FooterComponent />
                </div>
            </ApiContext.Provider>
        </ConfigurationContext.Provider>
    );
}
