import styled, { css } from 'styled-components';

export const AlbumOverlayContainer = styled.div<{
    $isMobile: boolean;
    $isLandscape: boolean;
}>`
    z-index: 3;
    position: fixed;
    display: flex;
    flex-direction: ${({ $isMobile, $isLandscape }) =>
        $isMobile && $isLandscape ? 'row' : 'column'};
    top: ${({ $isMobile }) => ($isMobile ? undefined : '5vh')};
    left: ${({ $isMobile }) => ($isMobile ? undefined : '10vw')};
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: ${({ $isMobile }) => ($isMobile ? undefined : '20px')};
    backdrop-filter: blur(5px);
    width: ${({ $isMobile }) => ($isMobile ? '100vw' : '80vw')};
    height: ${({ $isMobile }) => ($isMobile ? '100vh' : '90vh')};
`;

export const CloseButton = styled.button<{ $isMobile: boolean }>`
    position: absolute;
    font-size: 24px;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    right: 10px;
    top: 10px;
`;

export const MainPic = styled.div<{
    $isMobile: boolean;
    $isLandscape: boolean;
}>`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${({ $isMobile, $isLandscape }) =>
        $isMobile
            ? $isLandscape
                ? 'calc(90vh - 30px)'
                : 'calc(90vw - 30px)'
            : '65%'};
    width: ${({ $isMobile, $isLandscape }) =>
        $isMobile
            ? $isLandscape
                ? 'calc(90vh - 30px)'
                : 'calc(90vw - 30px)'
            : undefined};

    img {
        max-height: 100%;
        max-width: 100%;
    }
`;

export const Carousel = styled.div<{
    $isMobile: boolean;
    $isLandscape: boolean;
}>`
    width: ${({ $isMobile, $isLandscape }) =>
        $isMobile && $isLandscape ? 'calc(100vw - 90vh)' : undefined};
    display: ${({ $isMobile }) => ($isMobile ? undefined : 'flex')};
    padding: 0 20px;
    scrollbar-color: white rgba(0, 0, 0, 0);

    ${({ $isMobile, $isLandscape }) => {
        if ($isMobile) {
            if ($isLandscape) {
                return css`
                    height: 100%;
                    overflow-y: scroll;

                    > div {
                        display: inline-flex;
                    }
                `;
            }

            return css`
                height: calc(100vh - 90vw);
                overflow-x: scroll;
                grid-auto-columns: 200px;

                > div {
                    display: inline-flex;
                }
            `;
        }

        return css`
            height: 25%;
            overflow-x: scroll;
        `;
    }}
`;

export const CarouselItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    padding: 10px;

    img {
        cursor: pointer;
        max-width: 150px;
        max-height: 150px;
    }
`;
