import { useMemo } from 'react';
import { Mount } from '../../../enums/mount';
import './mount.css';
import { useConfiguration } from '../../../hooks/use-configuration';
import clsx from 'clsx';

interface MountComponentInput {
    mount: Mount | null;
}

function createStyleValue(
    backgroundColor: string,
    color: string = 'initial',
): React.CSSProperties {
    return {
        backgroundColor,
        color,
    };
}

export function MountComponent({ mount }: MountComponentInput): JSX.Element {
    const { isMobile } = useConfiguration();

    const mountStyle: React.CSSProperties = useMemo(() => {
        switch (mount) {
            case Mount.A:
                return createStyleValue('lightgreen');
            case Mount.M42:
                return createStyleValue('mediumseagreen', 'white');
            case Mount.EXAKTA:
                return createStyleValue('cadetblue', 'white');
            case Mount.PK:
                return createStyleValue('darkgreen', 'white');
            case Mount.OM:
                return createStyleValue('darkgoldenrod', 'white');
            case Mount.NEX:
                return createStyleValue('lime');
            case Mount.EF:
                return createStyleValue('teal', 'white');
            case Mount.FD:
                return createStyleValue('darkred', 'white');
            case Mount.OPEMA:
                return createStyleValue('black', 'white');
            case Mount.L39:
                return createStyleValue('orange');
            case Mount.P6:
                return createStyleValue('purple', 'white');
            case Mount.KIEV:
                return createStyleValue('lightgrey');
            case Mount.CONTAX:
                return createStyleValue('honeydew');
            case Mount.MD:
                return createStyleValue('ivory');
            case Mount.WERRA:
                return createStyleValue('lavender');
            case Mount.ALTIX:
                return createStyleValue('cyan');
            case Mount.PRAKTINA:
                return createStyleValue('yellow');
            case Mount.ICAREX:
                return createStyleValue('gold');
            case Mount.C:
                return createStyleValue('#bbb38e');
            case Mount.KONI:
                return createStyleValue('#62cbc7');
            case Mount.PENTAX_Q:
                return createStyleValue('#857e1a');
            default:
                return createStyleValue('black', 'white');
        }
    }, [mount]);

    return (
        <div
            className={clsx(
                'mount',
                isMobile ? 'mount-mobile' : 'mount-desktop',
            )}
            style={mountStyle}
        >
            {mount?.toString() ?? 'N/A'}
        </div>
    );
}
