import { useCallback, useEffect, useState } from 'react';
import { Album } from '../../../../models/album';
import {
    AlbumOverlayContainer,
    Carousel,
    CarouselItem,
    CloseButton,
    MainPic,
} from './album-overlay.styles';
import { Photo } from '../../../../models/photo';
import { useConfiguration } from '../../../../hooks/use-configuration';

interface AlbumOverlayProps {
    album: Album;
    closeOverlay: () => void;
}

export const AlbumOverlay = ({
    album,
    closeOverlay,
}: AlbumOverlayProps): JSX.Element => {
    const [chosenPic, setChosenPic] = useState<Photo>(album.photos[0]);
    const { isMobile, isLandscape } = useConfiguration();

    const onEscPressed = useCallback((event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            closeOverlay();
        }
    }, []);

    const onClickOutside = useCallback((event: MouseEvent): void => {
        closeOverlay();
    }, []);

    useEffect(() => {
        document.body.style.setProperty('overflow', 'hidden');
        document.addEventListener('keyup', onEscPressed);
        setTimeout(() => {
            document.addEventListener('click', onClickOutside);
        }, 100);

        return () => {
            document.body.style.setProperty('overflow', 'auto');
            document.removeEventListener('keyup', onEscPressed);
            document.removeEventListener('click', onClickOutside);
        };
    }, []);

    return (
        <AlbumOverlayContainer
            $isMobile={isMobile}
            $isLandscape={isLandscape}
            onClick={(e) => e.stopPropagation()}
        >
            <CloseButton
                $isMobile={isMobile}
                onClick={() => closeOverlay()}
            >
                &#10005;
            </CloseButton>
            <MainPic
                $isMobile={isMobile}
                $isLandscape={isLandscape}
            >
                <img src={chosenPic.url} />
            </MainPic>
            <Carousel
                $isMobile={isMobile}
                $isLandscape={isLandscape}
            >
                {album.photos.map((p) => {
                    return (
                        <CarouselItem
                            key={p.id}
                            onClick={() => setChosenPic(p)}
                        >
                            <img src={p.thumb} />
                        </CarouselItem>
                    );
                })}
            </Carousel>
        </AlbumOverlayContainer>
    );
};
