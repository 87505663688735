import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../router/router';
import { Post } from '../../models/post';
import { LoadingComponent } from '../loading/loading';

interface OpenedPostComponentInput {
    id: number;
}

export function OpenedPostComponent({
    id,
}: OpenedPostComponentInput): JSX.Element {
    const navigate = useNavigate();

    const onBackButtonClick = useCallback(() => {
        navigate('/blog');
    }, [navigate]);

    const { getPost } = useApi();
    const [post, setPost] = useState<Post | undefined>();

    const setPostFn = useCallback(
        (post: Post) => {
            setPost(post);
        },
        [setPost],
    );

    useEffect(() => {
        getPost(id, setPostFn);
    }, [id, getPost, setPostFn]);

    if (!post) {
        return <LoadingComponent />;
    }

    return (
        <>
            {id}
            <input
                type="button"
                onClick={onBackButtonClick}
            />
        </>
    );
}
