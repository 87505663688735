import { useCallback, useState } from 'react';
import { Album } from '../../../../models/album';
import './album.css';
import { useConfiguration } from '../../../../hooks/use-configuration';
import clsx from 'clsx';
import { Photo } from '../../../../models/photo';
import { AlbumContainer } from './album.styled';

interface AlbumComponentProps {
    album: Album;
    onAlbumClicked: () => void;
}

const getRandomPictures = (album: Album): Photo[] => {
    const pictures: Photo[] = [];
    if (album.photos.length === 0) {
        return [];
    }

    let attempt = 0;
    while (pictures.length < carouselSize && attempt < 20) {
        const number = Math.floor(Math.random() * album.photos.length);

        if (!pictures.includes(album.photos[number])) {
            pictures.push(album.photos[number]);
        }

        attempt++;
    }

    return pictures;
};

const carouselSize = 3;

export function AlbumComponent({
    album,
    onAlbumClicked,
}: AlbumComponentProps): JSX.Element {
    const { isMobile } = useConfiguration();

    const [shownPictures, setShownPictures] = useState(
        getRandomPictures(album).map((p) => p.thumb),
    );

    return (
        <AlbumContainer onClick={() => onAlbumClicked()}>
            <a href="#">{album.title}</a>

            <div
                className={clsx(
                    'album-carousel',
                    isMobile
                        ? 'album-carousel-mobile'
                        : 'album-carousel-desktop',
                )}
            >
                {shownPictures.map((p, i) => {
                    return (
                        <div
                            key={i}
                            style={{ backgroundImage: `url('${p}')` }}
                        ></div>
                    );
                })}
            </div>
        </AlbumContainer>
    );
}
