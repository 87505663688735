import { MenuItem } from '../../../models/menu-items';
import './menu-item.css';
import clsx from 'clsx';
import { useMenuItem } from './use-menu-item';

interface MenuItemInput {
    menuItem: MenuItem;
}

export function MenuItemComponent({ menuItem }: MenuItemInput): JSX.Element {
    const { isMobile, handleClick } = useMenuItem({ menuItem });

    return (
        <button
            className={clsx('menu-button', 'clickable', {
                'menu-button-desktop': !isMobile,
            })}
            type="button"
            onClick={handleClick}
        >
            {menuItem.title}
        </button>
    );
}
