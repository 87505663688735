import { useNavigate } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/use-configuration';
import { useCallback } from 'react';
import { MenuItem } from '../../../models/menu-items';

interface UseMenuItemProps {
    menuItem: MenuItem;
}

interface UseMenuItemApi {
    isMobile: boolean;
    handleClick: () => void;
}

export const useMenuItem = ({ menuItem }: UseMenuItemProps): UseMenuItemApi => {
    const navigate = useNavigate();
    const { isMobile } = useConfiguration();

    const handleClick = useCallback(() => {
        navigate(menuItem.href);
    }, [menuItem]);

    return {
        isMobile,
        handleClick,
    };
};
