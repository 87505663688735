import { useCallback, useEffect, useMemo, useState } from 'react';
import { menuItems } from '../../../models/menu-items';
import { MenuItemComponent } from '../menu-item/menu-item';
import { useLocation } from 'react-router-dom';
import './menu-mobile.css';
import { useConfiguration } from '../../../hooks/use-configuration';

export function MenuMobileComponent(): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const { getPage } = useConfiguration();

    const onMenuClick = useCallback(() => {
        setIsOpen((old) => !old);
    }, [setIsOpen]);

    useEffect(() => {
        setIsOpen(false);
    }, [location, setIsOpen]);

    const menuButton = (
        <div
            onClick={onMenuClick}
            className="hamburger-menu-button"
        >
            <div></div>
            <div></div>
            <div></div>
        </div>
    );

    const page = useMemo(() => {
        return getPage(location.pathname);
    }, [location, getPage]);

    return (
        <div className="menu-layer">
            <div className="menu-left">
                {menuButton}

                {isOpen &&
                    menuItems.map((menuItem) => (
                        <MenuItemComponent
                            key={menuItem.title}
                            menuItem={menuItem}
                        />
                    ))}
            </div>

            <h1>{page?.title}</h1>
        </div>
    );
}
