import { useEffect, useMemo, useState } from 'react';
import { MenuItem, menuItems } from '../models/menu-items';

export interface UseConfigurationApi {
    isMobile: boolean;
    isLandscape: boolean;
    getPage: (href: string) => MenuItem | undefined;
}

const isLandscapeCheck = (): boolean => {
    return document.body.clientHeight < document.body.clientWidth;
};

export function useConfiguration(): UseConfigurationApi {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);
    const [isLandscape, setIsLandscape] = useState(isLandscapeCheck());

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        setIsLandscape((old) => {
            const newValue = isLandscapeCheck();

            if (old === newValue) {
                return old;
            }

            return newValue;
        });
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = useMemo(() => {
        return width <= 768 || height <= 768;
    }, [width]);

    const getPage = (href: string): MenuItem | undefined => {
        return menuItems.find((m) => m.href === href);
    };

    return {
        isMobile,
        isLandscape,
        getPage,
    };
}
